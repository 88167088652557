/* remove bootsrap design */
.ouline-none {
  outline: none;
}

.nav-con {
  z-index: 10000;
}

.nav-font {
  font-family: "Open Sans", sans-serif;
}

.mntech-logo {
  width: 8.75rem;
  height: 4.375rem;
}

.nav-menu-link {
  color: #070606;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  padding: 8px;
  font-size: 18px;
}

.nav-menu-link:hover {
  color: #f91938;
}

.nav-hover:after {
  display: block;
  content: "";
  border-bottom: solid 2px #f91938;
  transform: scaleX(0);
  transition: transform 600ms ease-in-out;
}

.nav-hover:hover:after {
  transform: scaleX(1);
}

.active-link {
  color: #f91938;
}

.hamburger-menu {
  width: 65px;
  height: 45px;
  color: #f91938;
  border: 1px solid #f91938;
  border-radius: 0.25rem;
  padding: 0 0.2rem;
  margin-top: 10px;
}

/* dropdown style */
.dropdown {
  width: 240px;
  /* border: 1px solid #f91938; */
}

.dropdown-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.dropdown-nav-menu {
  font-size: 16px;
  color: #070606;
  padding: 8px 12px;
}

.dropdown-nav-menu:hover {
  color: #ffffff;
  background: #f91938;
}

/* utils */
.ms-2-up {
  margin-left: 10px;
}

@media screen and (max-width: 991px) {
  .nav-menu-link {
    margin: 8px 0;
    padding: 0 8px;
  }

  /* .nav-md-sm-style {
    border-bottom: 1px solid #333333;
  } */

  .dropdown-nav-menu {
    color: #333333;
    padding: 0;
  }
  
  .dropdown-nav-menu:hover {
    color: #000;
    background: none;
  }

}
