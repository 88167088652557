.ind-icon-container {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-size-ind {
  width: 35px;
  height: 35px;
}

.specific-ind-card {
  height: 358px;
}
