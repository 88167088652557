.home-banner-container {
  height: 100vh;
}

.hero-area {
  position: relative;
  width: 100%;
}

.banner-img {
  height: 80vh;
  position: absolute;
  top: 0;
  left: 50%;
}

.intro-container {
  padding: 8rem 0 0 0;
  z-index: 1000;
}

.intro-text-bold {
  font-size: 2.8rem;
  line-height: 60px;
  margin-bottom: 10px;
  font-weight: 800;
}

.intro-text-light {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #abacae;
}

/* Extra small to medium devices (phones, less than 768px) */
@media (max-width: 767.99px) {
  .home-banner-container {
    height: 22vh;
    margin-bottom: 4rem;
  }

  .banner-img {
    height: 22vh;
    left: 50%;
    top: 0;
  }

  .intro-container {
    padding: 0.9rem 0 0 0;
  }

  .intro-text-bold {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }

  .intro-text-light {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  .text-block {
    display: block;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .home-banner-container {
    height: 24vh;
    margin-bottom: 4rem;
  }

  .banner-img {
    height: 24vh;
    left: 50%;
    top: 0;
  }

  .intro-container {
    padding: 1.1rem 0 0 0;
  }

  .intro-text-bold {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .intro-text-light {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .text-block {
    display: block;
  }
}
