.scroll-to-top-button {
  position: fixed;
  right: 15px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  color: #ffffff;
}

.up-arrow {
  height: 40px;
}
