.address-card {
  width: 444px;
  height: 472px;
  border: 1px solid #f91938;
  border-radius: 10px;
}

.address-icon {
  width: 25px;
  height: 25px;
}

.follow-us {
  margin-top: 110px;
}

.follow-us-border {
  width: 6.8rem;
  border-bottom: 1px solid #f91938;
}

.input-parent {
  margin-bottom: 20px;
}

.input-field {
  font-size: 1.1rem;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  transition: all 0.3s;
}

.input-field:focus {
  outline: 1px solid #f91938;
}

.lg-w-50 {
  width: 100%;
}

.text-area {
  height: 200px;
  resize: vertical;
}

.contact-msg-btn {
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
  padding: 10px 30px;
  border-top: 1px solid #f91938;
  border-bottom: 1px solid #f91938;
  border-left: 1px dotted #f91938;
  border-right: 1px dotted #f91938;
  border-radius: 30px;
  background-color: #f91938;
  transition: all 0.2s linear;
}

.contact-msg-btn:hover {
  color: #f91938;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}

@media (max-width: 991px) {
  .address-card {
    width: 100%;
  }
}
