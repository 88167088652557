/* my utility classes */
/* mntech colors */
.mntech-primary-clr-bg {
  background-color: #f91938;
}

.mntech-primary-clr-text {
  color: #f91938;
}

.mntech-primary-clr-text-hover:hover {
  color: #000000;
}

.mntech-secondary-clr-bg {
  background-color: #f8f8f8;
}

.mntech-secondary-clr-text {
  /* color: #b1b2b4; */
  color: #515151;
}

/* sizing and specing utilities */
/* min hight 100vh */
.m-h-100 {
  min-height: 100vh;
}

.m-h-50 {
  min-height: 50vh;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.py-6 {
  padding: 6rem 0;
}

.mt-6 {
  margin-top: 5rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mt-10 {
  margin-top: 10rem;
}

.my-12 {
  margin: 12rem 0;
}

.ms-8 {
  margin-left: 8rem;
}

.gap-6 {
  column-gap: 6rem;
}

.gap-7 {
  column-gap: 7rem;
}

.gap-x-2 {
  column-gap: 2rem;
}

/* text styling && spaceing starts */
.line-height-2 {
  line-height: 1.8rem;
}

.line-height-3 {
  line-height: 2.5rem;
}

.fs-7 {
  font-size: 1.2rem;
}

.fs-8 {
  font-size: 12px;
}

.fs-lg-4 {
  font-size: 2.8125rem;
}

.ta-same {
  text-align: justify;
  text-justify: inter-word;
}
/* text styling && spaceing ends */

/* positioning */
.top-25 {
  top: 25%;
}

.top-30 {
  top: 30%;
}

.top-40 {
  top: 40%;
}

.left-15 {
  left: 15%;
}

/* border bottom under section title */
.border-b {
  border-bottom: 1px solid #f91938;
  width: 75px;
  text-align: center;
  margin: 0 auto;
}

.border-b-gray {
  border-bottom: 1px solid #808080;
}

.border-mntech {
  border: 1px solid #f91938;
}

/* display, sizing and spacing utilities */
.d-flex-center-both {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* common style for card */

/* shadow effects utilities */
.card-height {
  height: auto;
}

.card-shadow {
  box-shadow: 0 0 3px 3px #f4f4f4;
  transition: all ease-in-out 0.2s;
}

.card-hover:hover {
  box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.066);
}

.card-logo {
  color: #f91938;
}

.card-hover:hover .card-logo {
  transition: ease-in-out all 0.2s;
  background-color: #f91938;
  color: #f4f4f4;
}

.card-hover:hover .service-title {
  color: #f91938;
}

/* Link style */
.no-underline {
  text-decoration: none;
}

.underline-hover:hover {
  text-decoration: underline;
}

.text-underline {
  text-decoration: underline;
}

/* cursor */
.cursor-pointer {
  cursor: pointer;
}

/* text style */
.uppercase {
  text-transform: uppercase;
}

/* highlights */
.z-index-100 {
  z-index: 100;
}

/* mntech button starts */
.mntech-btn {
  color: #f91938;
  border: 1px solid #f91938;
  font-size: 14px;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 30px;
  background: #ffffff;
  margin-top: 10px;
  text-decoration: none;
  list-style: none;
  transition: all 0.2s linear;
}

.mntech-btn:hover {
  color: #fff;
  background-color: #f91938;
}

.mntech-btn-fill {
  color: #ffffff;
  border: 1px solid #f91938;
  font-size: 14px;
  margin-right: 10px;
  padding: 10px 30px;
  border-radius: 30px;
  background: #f91938;
  margin-top: 10px;
  text-decoration: none;
  list-style: none;
  transition: all 0.2s linear;
}

.mntech-btn-fill:hover {
  color: #f91938;
  background-color: #ffffff;
}
/* mntech button starts */

/* some styles inside components starts */
.specific-desc > p {
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  text-justify: inter-word;
}

.banner-text {
  max-width: 700px;
}

.fs-ind-banner {
  font-size: 1.25rem;
}

.w-h-icon {
  width: 35px;
  height: 35px;
}
/* some styles inside components ends */

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  .mntech-btn {
    margin-top: 5px;
    padding: 8px 20px;
  }

  .sm-w-100 {
    width: 100%;
  }

  .banner-text {
    width: 100%;
  }

  /* .fs-ind-banner {
    font-size: .9rem;
  } */

  .fs-xsm-10 {
    font-size: 1rem;
  }
}

@media (max-width: 767.9px) {
  .fs-sm-1 {
    font-size: 0.9rem;
  }

  .fs-sm-2 {
    font-size: 1rem;
  }

  /* inside components style */
  .banner-text {
    width: 90%;
  }

  /* .fs-ind-banner {
    font-size: 1rem;
  } */
}

/* medium screen */
/* @media (min-width: 768px) and (max-width: 991px) { */
@media screen and (max-width: 991px) {
  /* layoutes */
  .md-f-col {
    flex-direction: column;
    align-items: center;
    padding: auto 3rem;
  }

  /* sizing and width */
  .md-w-100 {
    width: 100%;
  }

  .md-d-flex-right {
    justify-content: end;
  }

  /* positioning */
  .md-top-10 {
    top: 10%;
  }

  .md-left-25 {
    left: 5%;
  }

  /* inside components style */
  .banner-text {
    width: 80%;
  }

  /* .fs-ind-banner {
    font-size: 1.15rem;
  } */

  .fs-md-8 {
    font-size: 1.7rem;
  }
}
