/* @import url("https://fonts.googleapis.com/css?family=Arvo:700|Open+Sans"); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');

/* utils */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* style for targeted tags */

html {
  overflow-x: hidden;
}

body,
html {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #abacae;
  line-height: 25px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-size: 38px;
  color: #222222;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}

a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

a,
a:hover {
  text-decoration: none;
}

a,
a:focus {
  outline: none;
}

p {
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  margin: 0px;
  font-size: 14px;
}

ul,
ol {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

ul li,
ol li {
  list-style: none;
}