.partner-text {
    line-height: 2.4rem;
    padding: 0 1rem;
    font-size: 20px;
}

.partner-image {
    width: 300px;
    height: 320px;
}

/* .px-partner-5 {
    padding: 0 10rem;
} */

@media screen and (max-width: 991px) {
    .px-partner-5 {
        padding: 0;
    }
}