.specific-banner-card {
  width: 30rem;
  height: 30rem;
  background: #ffffff;
  box-shadow: 0px 4px 20px 2px rgba(114, 114, 114, 0.1);
  border-radius: 15px;
}

.specific-serv-banner-img img {
  width: 500px;
  height: 450px;
}

.specific-serv-icon {
  width: 60px;
  height: 60px;
}

/* .specific-serv-icon-2 {
  width: 60px;
  height: 100px;
} */

.triangle-icon {
  width: 20px;
  height: 20px;
}

.ind-code {
  width: 408px;
  height: 274px;
}

.ind-font {
  font-size: 20px;
}

.partner-logo {
  width: 100px;
  height: 50px;
}

.custom-overlay {
  height: 400px;
  width: 100%;
  text-align: center;
  padding-top: 100px;
  color: white;
  overflow: hidden;
  background: #c04848; /* fallback for old browsers */
  background: linear-gradient(rgba(109, 0, 109, 0.8), rgba(172, 43, 43, 0.8));
  /* background-size: cover;
  background-repeat: no-repeat; */
}

.border-right {
  border-right: 1px solid rgba(151, 151, 151, 0.2);
}

.h-text-area {
  height: 100px;
}

@media screen and (max-width: 991px) {
  .specific-banner-card {
    width: 100%;
  }

  .specific-serv-banner-img img {
    width: 100%;
    height: 100%;
  }

  .md-border-right-none {
    border-right: none;
  }
}
