.mission-card,
.vission-card {
  position: relative;
  width: 33.75rem;
  height: 12.5rem;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* medium screen */
/* @media screen and (max-width: 768px) {
  .mission-vission-container {
    flex-direction: column;
    align-items: center;
    padding: auto 3rem;
  }
} */

/* .mission-card:hover {
  border: 3px solid #f91938;
  transition: border-width 0.5s, border-color 0.5s;
}

.mission-card:hover .card-body {
  transform: translate(0, 5px);
  transition: transform 0.5s;
} */

/* Define the styles for the animated border */
.mission-card::before,
.vission-card::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 0;
  height: 0;
  border: 1px solid transparent;
}

/* Define the animation properties */
@keyframes border {
  0% {
    top: -3px;
    left: -3px;
    width: 0;
    height: 0;
    border-color: #f91938;
  }
  100% {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-color: #f91938;
  }
}

/* Apply the animation properties on hover */
.mission-card:hover::before,
.vission-card:hover::before {
  animation: border 1s forwards;
}

.mission-title,
.vission-title {
  width: 75px;
  padding-bottom: .4rem;
  border-bottom: 1px solid #f91938;
}
