.service-card-height {
  height: 280px;
}

.service-icon-container {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.icon-size {
  width: 70px;
  height: 70px;
} */

.service-icon {
  width: 28px;
  height: 28px;
}

.bottom-read-more {
  bottom: 2.5rem;
}
