.footer {
  color: #000;
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.footer-route-link {
  color: #000;
}

.footer-route-link:hover {
  color: #f91938;
}

.footer-social-link {
  width: 32px;
  height: 32px;
  transition: all ease-in-out 0.4s;
  color: #222222;
}

.footer-social-link:first-child:hover {
  background-color: #3b5999;
  color: #fff;
}

.footer-social-link:last-child:hover {
  background-color: #1260a2;
  color: #fff;
}

.mntech-footer-img {
  width: 240px;
  height: 22px;
}
