.header .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000000 15.31%, rgba(7, 6, 6, 0.01) 71.08%);
  opacity: 4;
}

.join-as-heading {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 48px;
  color: #ffffff;
}

.join-as-desc {
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 2rem;
}

.bottom-6 {
  bottom: 6rem;
}

/* join partner form  */

.join-partner-form {
  width: 57rem;
  height: auto;
}

/* .join-partner-form input {
    outline: #f91938;
} */

.h-partner-50 {
  height: 14rem;
}

@media screen and (max-width: 991px) {
  .join-partner-form {
    width: 100%;
  }
}
