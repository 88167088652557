/* Default styles for the map */
.GoogleMap {
    width: 582px;
    height: 472px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 767px) {
    .GoogleMap {
      width: 100%;
      height: 300px;
    }
  }
  