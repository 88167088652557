.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  
  .spinner-inner {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 4px solid #ccc;
    border-top-color: #f93831;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }