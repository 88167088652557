.promote-card {
  width: 350px;
  height: 60px;
  margin: 30px 0 0;
  border: 0;
  transition: all ease-in-out 0.2s;
}

.promote-card:hover .promote-title {
  color: #f91938;
}

.intro-mobile-img img {
  width: 20rem;
  height: 18rem;
  /* margin-top: 3rem; */
}

.icon {
  width: 30px;
  height: 30px;
  margin-top: 1rem;
}

/* medium screen */
@media screen and (max-width: 991px) {
  .intro-mobile-img img {
    margin: 0;
  }
}
