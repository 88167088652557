.card-w-h {
  width: 550px;
  height: 184px;
}

.bg-light-gray {
  background-color: #f9f9f9;
}

.pos-text {
  font-size: 14px;
}

.quote-text {
  font-size: 12px;
}

.mem-card {
  width: 230px;
  height: 120px;
}

.mem-card-title {
  font-size: 18px;
}

.mem-card-shadow {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(255, 0, 0, 0.2);
}

/* social icons syle */
.facebook {
  color: #3c5a98;
  width: 22px;
  height: 22px;
  margin-left: 0.6rem;
}

.twitter {
  color: #01aced;
  width: 22px;
  height: 22px;
  margin-left: 0.6rem;
}

.linkedin {
  color: #0157b3;
  width: 22px;
  height: 22px;
  margin-left: 0.6rem;
}

@media screen and (max-width: 991px) {
  .card-w-h {
    width: 100%;
    height: auto;
  }
}
